import { getProjectDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetEachProjectDetails = {
  data() {
    return {
      getEachProjectDetails: {},
      overlay: false,
      project_details: {},
      api_key: "",
    };
  },
  methods: {
    async GetEachProjectDetailsMethod(project_id) {
      try {
        if (this.$route.name != "Members") {
          this.overlay = true;
        }
    
        let result = await API.graphql(
          graphqlOperation(getProjectDetails, {
            input: {
              // project_id: this.$store.getters.get_current_project_details.project_id,
              project_id: !project_id ? this.$store.getters.get_current_project_details.project_id : project_id,
              // project_id: "1cd695c2-5287-45b2-84f1-6064dc125610",
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );
        this.getEachProjectDetails = JSON.parse(result.data.GetProjectDetails)[0];
        if (!project_id) {
          await this.$store.commit("SET_CURRENTPROJECT_DETAILS", JSON.parse(result.data.GetProjectDetails)[0]);
          this.project_details["Project Name"] = this.getEachProjectDetails.project_name;
          this.project_details["Project ID"] = this.getEachProjectDetails.project_display_id;
          this.project_details["Project Description"] = this.getEachProjectDetails.project_description;
          this.api_key = this.getEachProjectDetails.project_api_key;
          this.project_details["My Role"] = this.getEachProjectDetails.roles
            .filter((item) => item != "APPROVER")
            .join(", ")
            .replaceAll("_", " ");
          this.project_details["Owner"] = this.getEachProjectDetails.created_by;
          this.project_details["Developer Lead"] = this.getEachProjectDetails.developer_lead_email_id;
          this.project_details["Test Lead"] = this.getEachProjectDetails.tester_lead_email_id;
          this.project_details["Developers"] = this.getEachProjectDetails.no_of_developers;
          this.project_details["Testers"] = this.getEachProjectDetails.no_of_testers;
          this.project_details["Observers"] = this.getEachProjectDetails.no_of_observers
            ? this.getEachProjectDetails.no_of_observers
            : 0;
          this.project_details["UnAcknowledged Issues"] = this.getEachProjectDetails.no_of_unacknowledged_issues;
          this.project_details["Assigned Issues"] = this.getEachProjectDetails.no_of_assigned_issues;
          this.project_details["Fixed Issues"] = this.getEachProjectDetails.no_of_fixed_issues;
          this.project_details["Closed Issues"] = this.getEachProjectDetails.no_of_closed_issues;
          this.project_details["Rejected Issues"] = this.getEachProjectDetails.no_of_rejected_issues;
          this.$forceUpdate();
          this.overlay = false;
        }
        return this.getEachProjectDetails;
      } catch (error) {
        this.overlay = false;
        this.getEachProjectDetails = {};
        console.log(error);
      }
    },
  },
};
