/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject($input: CreateProjectInput!) {
    CreateProject(input: $input)
  }
`;
export const closeOrReopenProject = /* GraphQL */ `
  mutation CloseOrReopenProject($input: CloseOrReopenProjectInput!) {
    CloseOrReopenProject(input: $input)
  }
`;
export const reportIssue = /* GraphQL */ `
  mutation ReportIssue($input: ReportIssueInput!) {
    ReportIssue(input: $input)
  }
`;
export const approveOrRejectIssueForTestLead = /* GraphQL */ `
  mutation ApproveOrRejectIssueForTestLead(
    $input: ApproveOrRejectIssueForTestLead!
  ) {
    ApproveOrRejectIssueForTestLead(input: $input)
  }
`;
export const assignOrCloseOrReopenOrFixIssue = /* GraphQL */ `
  mutation AssignOrCloseOrReopenOrFixIssue(
    $input: AssignOrCloseOrReopenOrFixIssueInput!
  ) {
    AssignOrCloseOrReopenOrFixIssue(input: $input)
  }
`;
export const addUsersToProject = /* GraphQL */ `
  mutation AddUsersToProject($input: AddUsersToProjectInput) {
    AddUsersToProject(input: $input)
  }
`;
export const removeUsersFromProject = /* GraphQL */ `
  mutation RemoveUsersFromProject($input: RemoveUsersFromProjectInput) {
    RemoveUsersFromProject(input: $input)
  }
`;
export const editProject = /* GraphQL */ `
  mutation EditProject($input: EditProjectInput!) {
    EditProject(input: $input)
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($input: DeleteProjectInput!) {
    DeleteProject(input: $input)
  }
`;
export const transferOwnership = /* GraphQL */ `
  mutation TransferOwnership($input: TransferOwnershipInput!) {
    TransferOwnership(input: $input)
  }
`;
export const addOrRemoveApprovers = /* GraphQL */ `
  mutation AddOrRemoveApprovers($input: AddOrRemoveApproversInput!) {
    AddOrRemoveApprovers(input: $input)
  }
`;
export const convertIssueDataToExcel = /* GraphQL */ `
  mutation ConvertIssueDataToExcel($input: ConvertIssueDataToExcelInput!) {
    ConvertIssueDataToExcel(input: $input)
  }
`;
export const withdrawOwnershipTransfer = /* GraphQL */ `
  mutation WithdrawOwnershipTransfer($input: WithdrawOwnershipTransferInput!) {
    WithdrawOwnershipTransfer(input: $input)
  }
`;
export const withdrawIssueForUser = /* GraphQL */ `
  mutation WithdrawIssueForUser($input: WithdrawIssueForUserInput!) {
    WithdrawIssueForUser(input: $input)
  }
`;
export const bulkAssignIssuesToDeveloper = /* GraphQL */ `
  mutation BulkAssignIssuesToDeveloper(
    $input: BulkAssignIssuesToDeveloperInput!
  ) {
    BulkAssignIssuesToDeveloper(input: $input)
  }
`;
export const regenerateProjectApiKey = /* GraphQL */ `
  mutation RegenerateProjectApiKey($input: RegenerateProjectApiKeyInput!) {
    RegenerateProjectApiKey(input: $input)
  }
`;
export const updateUserEmailStatus = /* GraphQL */ `
  mutation UpdateUserEmailStatus($input: UpdateUserEmailStatusInput!) {
    UpdateUserEmailStatus(input: $input)
  }
`;
