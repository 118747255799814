/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listIssues = /* GraphQL */ `
  query ListIssues($input: ListIssuesInput!) {
    ListIssues(input: $input)
  }
`;
export const listIssuesOfUser = /* GraphQL */ `
  query ListIssuesOfUser($input: ListIssuesOfUserInput!) {
    ListIssuesOfUser(input: $input)
  }
`;
export const listProjectsOfAnUser = /* GraphQL */ `
  query ListProjectsOfAnUser($input: ListProjectsOfAnUserInput!) {
    ListProjectsOfAnUser(input: $input)
  }
`;
export const getCurrentUser = /* GraphQL */ `
  query GetCurrentUser($input: GetCurrentUserInput!) {
    GetCurrentUser(input: $input)
  }
`;
export const listUsersOfProject = /* GraphQL */ `
  query ListUsersOfProject($input: ListUsersOfProjectInput!) {
    ListUsersOfProject(input: $input)
  }
`;
export const getProjectDetails = /* GraphQL */ `
  query GetProjectDetails($input: GetProjectDetailsInput!) {
    GetProjectDetails(input: $input)
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs($input: ListLogsInput!) {
    ListLogs(input: $input)
  }
`;
export const listCommentsOfIssue = /* GraphQL */ `
  query ListCommentsOfIssue($input: ListCommentsOfIssueInput!) {
    ListCommentsOfIssue(input: $input)
  }
`;
export const listIssuesOfUserBasedOnIssueStatus = /* GraphQL */ `
  query ListIssuesOfUserBasedOnIssueStatus(
    $input: ListIssuesOfUserBasedOnIssueStatusInput!
  ) {
    ListIssuesOfUserBasedOnIssueStatus(input: $input)
  }
`;
export const listAssignedIssuesOfEachDeveloper = /* GraphQL */ `
  query ListAssignedIssuesOfEachDeveloper(
    $input: ListAssignedIssuesOfEachDeveloperInput!
  ) {
    ListAssignedIssuesOfEachDeveloper(input: $input)
  }
`;
export const listIssuesOfUserAcrossAllProjects = /* GraphQL */ `
  query ListIssuesOfUserAcrossAllProjects(
    $input: ListIssuesOfUserAcrossAllProjectsInput!
  ) {
    ListIssuesOfUserAcrossAllProjects(input: $input)
  }
`;
